<template>
<div class="tab-container">
  <div class="tab-header">
    <div :class="['tab-item', tab.code == active.code ? 'tab-select' : '']" v-for="(tab, tidx) in coms" :key="tidx" @click.stop="tabClick(tab)">
      {{ tab.name }}
      <Icon v-if="tab.code != 'Deaktop'" type="md-close" class="tab-close" @click.stop="closePage(tab)" />
    </div>
  </div>
  <div class="tab-content">
    <component v-bind:is="active.com"></component>
  </div>
</div>
</template>
<script>
export default {
  name: 'Header',
  components: {
  },
  data(){
    return {
      active: {},
      coms: []
    }
  },
  created: function () {
  },
  computed: {
  },
  watch: {
  },
  mounted: function(){
  },
  methods: {
    showMenu: function(menu){
      console.log('show menu', menu)
      if(this.coms.length > 20){
        this.$Modal.warning({
          title: "温馨提示",
          content: "页面太多会造成浏览器卡顿，请关闭一些页面"
        });
        return;
      }
      let coms = this.coms.filter(p => p.code == menu.code);
      if(coms.length == 0){
        this.coms.push(menu);
        this.active = menu;
      }else{
        this.active = coms[0];
      }
    },
    closePage: function(menu){
      console.log('close page', menu)
      for(let i = 0; i < this.coms.length; i++){
        if(this.coms[i].code == menu.code){
          this.coms.splice(i, 1);
          if(this.active.code == menu.code && i > 0){
            this.active = this.coms[i-1];
          }
        }
      }
    },
    tabClick: function(menu){
      console.log('tab click', menu)
      this.active = menu;
    },
  },
}
</script>
<style scoped>
.tab-container{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
.tab-header{
  border-bottom: solid 1px rgb(135, 159, 224);
  background-color: linear-gradient(to bottom, #DCDCDC, #fff);
  padding: 0 10px;
  display: flex;
  height: 30px;
  flex: none;
}
.tab-item{
  border: solid 1px rgb(135, 159, 224);
  padding: 3px 10px;
  margin: 3px 3px -1px 3px;
  background-color: #F8F8F9;
  border-radius: 5px 5px 0 0;
  cursor: pointer;

}
.tab-close{
  margin-right: -6px;
}
.tab-select{
  /* border: solid 1px blue; */
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(59, 170, 127);
  color: white;
}
.tab-content{
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
  position: relative;
  overflow: hidden;
}
</style>