<template>
<div class="header-container">
  <div class="header-logo"><Icon type="md-construct" style="margin-right: 10px;" />城市管理平台</div>
  <div class="header-menu">
    <div :class="['header-menu-item', active.code == item.code ? 'menu-select' : '']" v-for="(item, idx) in modules" :key="idx" @click.stop="menuClick(item)">{{ item.name }}</div>
  </div>
  <div class="header-user">
    <img style="width: 30px; height: 30px; margin-right: 8px" :src="img.headerUserIcon" />
    <HeaderDropDown />
  </div>
  <div class="drop-module" v-if="showSubs" @click.stop="pannelClick">
    <div class="sub-menu" v-for="(sub, idx) in active.children" :key="idx">
      <div class="sub-menu-item" v-if="sub.com" @click.stop="subClick(sub)">{{ sub.name }}</div>
      <div class="third-menu" v-else>
        <div class="sub-menu-header">{{ sub.name }}</div>
        <div class="sub-menu-item" v-for="(sitem, sidx) in sub.children" :key="`${idx}-${sidx}`" @click.stop="subClick(sitem)">{{ sitem.name }}</div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import HeaderDropDown from '@/views/common/menu/HeaderDropDown'
export default {
  name: 'Header',
  components: {
    HeaderDropDown,
  },
  data(){
    return {
      modules: [
        {code: 'Deaktop', name: '工作台', com: () => import('../pages/demo/Desktop.vue')},
        {code: 'View', name: '可视化', children: [
          {code: 'DataView', name: '数据可视化', children: [
            {code: 'Index511', name: '一屏查看', com: () => import('../pages/demo/Index1.vue')},
            {code: 'Index512', name: '二屏查看', com: () => import('../pages/demo/Index2.vue')},
            {code: 'Index513', name: '三屏查看', com: () => import('../pages/demo/Index3.vue')},
          ]},
          {code: 'OperateView', name: '操作可视化', children: [
            {code: 'Index611', name: '一屏操作', com: () => import('../pages/demo/Index4.vue')},
            {code: 'Index612', name: '二屏操作', com: () => import('../pages/demo/Index1.vue')},
            {code: 'Index613', name: '三屏操作', com: () => import('../pages/demo/Index2.vue')},
          ]},
        ]},
          {code: 'MonitorPowerbox', name: '配电管理', children: [
            {code: 'MonitorPowerboxConfig', name: '配置', children: [
              {code: 'Index11', name: '功能11', com: () => import('../pages/demo/Index3.vue')},
              {code: 'Index12', name: '功能12', com: () => import('../pages/demo/Index4.vue')},
            ]},
            {code: 'MonitorPowerboxOperation', name: '操作', children: [
              {code: 'Index21', name: '功能21', com: () => import('../pages/demo/Index1.vue')},
              {code: 'Index22', name: '功能22', com: () => import('../pages/demo/Index2.vue')},
            ]},
            {code: 'MonitorPowerboxAlarm', name: '报警', children: [
              {code: 'Index31', name: '功能31', com: () => import('../pages/demo/Index3.vue')},
              {code: 'Index32', name: '功能32', com: () => import('../pages/demo/Index4.vue')},
            ]},
            {code: 'MonitorPowerboxSearch', name: '查询', children: [
              {code: 'Index41', name: '功能41', com: () => import('../pages/demo/Index1.vue')},
              {code: 'Index42', name: '功能42', com: () => import('../pages/demo/Index2.vue')},
            ]},
          ]},
          {code: 'MonitorLight', name: '照明管理', children: [
            {code: 'MonitorLightConfig', name: '配置', children: [
              {code: 'Index51', name: '功能51', com: () => import('../pages/demo/Index3.vue')},
              {code: 'Index52', name: '功能52', com: () => import('../pages/demo/Index4.vue')},
            ]},
            {code: 'MonitorLightOperate', name: '操作', children: [
              {code: 'Index61', name: '功能61', com: () => import('../pages/demo/Index1.vue')},
              {code: 'Index62', name: '功能62', com: () => import('../pages/demo/Index2.vue')},
            ]},
            {code: 'MonitorLightAlarm', name: '报警', children: [
              {code: 'Index71', name: '功能71', com: () => import('../pages/demo/Index3.vue')},
              {code: 'Index72', name: '功能72', com: () => import('../pages/demo/Index4.vue')},
            ]},
            {code: 'MonitorLightSearch', name: '查询', children: [
              {code: 'Index81', name: '功能81', com: () => import('../pages/demo/Index1.vue')},
              {code: 'Index82', name: '功能82', com: () => import('../pages/demo/Index2.vue')},
            ]},
          ]},
          {code: 'MonitorLux', name: '光控管理', children: [
            {code: 'MonitorLuxConfig', name: '配置', children: [
              {code: 'Index181', name: '功能181', com: () => import('../pages/demo/Index3.vue')},
              {code: 'Index182', name: '功能182', com: () => import('../pages/demo/Index4.vue')},
            ]},
            {code: 'MonitorLuxOperate', name: '操作', children: [
              {code: 'Index281', name: '功能281', com: () => import('../pages/demo/Index1.vue')},
              {code: 'Index282', name: '功能282', com: () => import('../pages/demo/Index2.vue')},
            ]},
            {code: 'MonitorLuxSearch', name: '查询', children: [
              {code: 'Index381', name: '功能381', com: () => import('../pages/demo/Index3.vue')},
              {code: 'Index382', name: '功能382', com: () => import('../pages/demo/Index4.vue')},
            ]},
          ]},
        {code: 'Gis', name: 'GIS', children: [
          {code: 'Index11', name: '功能11', com: () => import('../pages/demo/Index1.vue')},
          {code: 'Index12', name: '功能12', com: () => import('../pages/demo/Index2.vue')},
        ]},
        {code: 'Maintance', name: '运维管理', children: [
          {code: 'Index211', name: '功能211', com: () => import('../pages/demo/Index3.vue')},
          {code: 'Index212', name: '功能212', com: () => import('../pages/demo/Index4.vue')},
        ]},
        {code: 'Assets', name: '资产管理', children: [
          {code: 'Index311', name: '功能311', com: () => import('../pages/demo/Index1.vue')},
          {code: 'Index312', name: '功能312', com: () => import('../pages/demo/Index2.vue')},
          {code: 'Index313', name: '功能313', com: () => import('../pages/demo/Index3.vue')},
          {code: 'Index314', name: '功能314', com: () => import('../pages/demo/Index4.vue')},
        ]},
        {code: 'Analyse', name: '决策管理', children: [
          {code: 'Index411', name: '功能411', com: () => import('../pages/demo/Index1.vue')},
          {code: 'Index412', name: '功能412', com: () => import('../pages/demo/Index2.vue')},
          {code: 'Index413', name: '功能413', com: () => import('../pages/demo/Index3.vue')},
          {code: 'Index414', name: '功能414', com: () => import('../pages/demo/Index4.vue')},
        ]},
        {code: 'System', name: '系统管理', children: [
          {code: 'Index511', name: '功能511', com: () => import('../pages/demo/Index1.vue')},
          {code: 'Index512', name: '功能512', com: () => import('../pages/demo/Index2.vue')},
          {code: 'Index513', name: '功能513', com: () => import('../pages/demo/Index3.vue')},
          {code: 'Index514', name: '功能514', com: () => import('../pages/demo/Index4.vue')},
        ]},
        {code: 'Site', name: '平台管理', children: [
          {code: 'Index611', name: '功能611', com: () => import('../pages/demo/Index1.vue')},
          {code: 'Index612', name: '功能612', com: () => import('../pages/demo/Index2.vue')},
          {code: 'Index613', name: '功能613', com: () => import('../pages/demo/Index3.vue')},
          {code: 'Index614', name: '功能614', com: () => import('../pages/demo/Index4.vue')},
        ]},
      ],
      active: null,
      showSubs: false,
    }
  },
  created: function () {
    this.active = this.modules[0];
  },
  computed: {
  },
  watch: {
  },
  mounted: function(){
    this.$emit('menuClick', this.active);
      console.log('click mounted', this.modules, this.active)
    window.onclick = () => {
      this.showSubs = false;
      console.log('window click', this.showSubs)
    }
  },
  methods: {
    menuClick: function(menu){
      let oldMenu = this.active ? this.active.code : '';
      this.active = menu;
      if(menu.com){
        this.$emit('menuClick', menu);
        this.showSubs = false;
      }else{
        if(menu.code == oldMenu){
          this.showSubs = !this.showSubs;
        }else{
          this.showSubs = true;
        }
      }
      console.log('click menu', menu, this.showSubs)
    },
    subClick: function(menu){
      this.$emit('menuClick', menu);
      this.showSubs = false;
    },
    pannelClick: function(){
      console.log('click pannel')
    },
  },
}
</script>
<style scoped>
.header-container{
  display: flex;
  background-color: #1D7EDA;
}
.header-logo{
  /* border: solid 1px red; */
  width: 250px;
  flex: none;
  font-size: 30px;
  color: white;
  padding-left: 15px;
  line-height: 48px;
}
.header-user{
  /* border: solid 1px red; */
  width: 150px;
  flex: none;
  /* margin: auto 9px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-menu{
  /* border: solid 1px red; */
  width: 350px;
  flex: auto;
  display: flex;
  justify-content: flex-end;
}
.header-menu-item{
  border-bottom: solid 3px #1D7EDA;
  cursor: pointer;
  flex: none;
  padding: 0 15px;
  font-size: 20px;
  color: white;
  line-height: 48px;
  user-select: none;
}
.menu-select{
  border-bottom: solid 3px white;
}
.drop-module{
  border: solid 1px rgba(0, 0, 255, 0.24);
  position: absolute;
  width: 100%;
  min-height: 100px;
  top: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  z-index: 100;
  padding: 10px;
}
.sub-menu{
  /* border: solid 1px rgba(0, 0, 255, 0.24); */
  width: 150px;
  margin: 0 10px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  cursor: default;
  /* padding: 0 10px; */
}
.third-menu{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: default;
}
.sub-menu-header{
  /* border: solid 1px rgba(0, 0, 255, 0.24); */
  width: 100%;
  cursor: default;
  font-weight: bolder;
  font-size: 20px;
  padding: 0 10px;
}
.sub-menu-item{
  /* border: solid 1px red; */
  user-select: none;
  cursor: pointer;
  display: flex;
  font-weight: 100;
  font-size: 15px;
  padding: 0 10px;
  margin-top: 5px;
}
</style>